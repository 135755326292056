<template>
  <div class="password-reset__view mt-5">
    <div class="password-reset__panel">

      <div class="password-reset__logo">
      </div>

      <div class="password-reset__headline">
        <p class="pb-3">Indtast venligst din email adresse i feltet nedenfor og tryk Nulstil.</p>
        <p>Hvis din adresse findes i systemet, modtager du kortvarigt en e-mail med et nyt kodeord.</p>
      </div>
      <div class="password-reset__form">
        
        <div class="field">
          <label class="label">E-mail</label>
          <div class="control has-icons-left">
            <input class="input" type="email" v-model="email" />
            <span class="icon is-small is-left">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
            </span>
          </div>
        </div>
        
        <div class="login-panel-controls">
          <button type="submit"
                  :class="['button', 'is-fullwidth', 'is-primary', isLoading && 'is-loading']"
                  @click="submit">Nulstil kodeord</button>
        </div>
        
        <div class="password-reset__links">
          <router-link to="/">Til login siden</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import APISTATE from '@/enums/APISTATE';

export default {
  name: 'password-reset-view',
  data: () => ({
    email: ''
  }),
  methods: {
    ...mapActions('appUsers', ['passwordReset']),
    async submit() {
      try {
        await this.passwordReset(this.email);
        this.$router.push('/');
      }
      catch (error) {
        console.error("Error while resetting password.");
      }
    }
  },
  computed: {
    ...mapGetters('appUsers', ['appUserApiState']),
    isLoading() {
      return this.appUserApiState === APISTATE.LOADING;
    }
  }
}
</script>

<style lang="scss">
.password-reset__view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .password-reset__panel {
    flex: 1;
    display: flex;
    flex-flow: column;
    max-width: 400px;
    gap: 15px;

    .login-panel-controls {
      margin-top: 10px;
    }
    .password-reset__logo {
      background-image: url(../assets/vucstorstroemlogo.svg);
      background-repeat: no-repeat;
      background-position: center;
      min-height: 150px;
    }
    .password-reset__links {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      a {
        color: black;
        text-decoration: underline;
        font-size: x-small;
      }
    }    
  }
}
</style>
